<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>走访信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="走访主题">
                <div style="width: 500px;">
                  {{ formData.title }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="区域">
                <div style="width: 500px;">
                  {{ formData.areaCodeName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="所属社区">
                <div style="width: 500px;">
                  {{ formData.communityName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="住户">
                <div style="width: 500px;">
                  {{ formData.realName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="住户手机">
                <div style="width: 500px;">
                  {{ formData.telephone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="走访类型">
                <div style="width: 500px;">
                  {{ formData.visitType }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="走访人">
                <div style="width: 500px;">
                  {{ formData.visitorName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="走访时间">
                <div style="width: 500px;">
                  {{ formData.visitTime }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="走访记录">
                <div style="width: 500px;">
                  {{ formData.content }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { visitRecordInfo } from '@/api/visitrecord'
export default {
  name: 'SeeVisit',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {}
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    visitRecordInfoApi (keyId) {
      const _this = this
      _this.seeInfoVisible = true
      _this.loading = true
      visitRecordInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.formData = res.result
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
